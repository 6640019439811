html,
body {
  scroll-behavior: smooth !important;
}

body {
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  color: $body-color;
  line-height: $body-line-Height;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  /***scroll-bar***/
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(251, 212, 81, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    background: $theme-color;
    border-radius: 0px;
  }
  &.home-3 {
    background: $smoke-color5;
    .btn {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.05em;
      padding: 20.5px 35px 20.5px;
    }
  }
}

iframe {
  border: none;
  width: 100%;
}

.slick-slide:focus,
button:focus,
a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  outline: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid $border-color;
}

th {
  font-weight: 700;
  color: $title-color;
}

td,
th {
  border: 1px solid $border-color;
  padding: 9px 12px;
}

a {
  color: $theme-color;
  text-decoration: none;
  outline: 0;
  transition: all ease 0.4s;
  &:hover {
    color: $title-color;
  }

  &:active,
  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
    outline: 0;
  }
}

button {
  transition: all ease 0.4s;
}

img {
  border: none;
  max-width: 100%;
}

ins {
  text-decoration: none;
}

pre {
  font-family: $body-font;
  background: #f5f5f5;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

span.ajax-loader:empty,
p:empty {
  display: none;
}

p {
  font-family: $body-font;
  margin: 0 0 18px 0;
  color: $body-color;
  line-height: $p-line-Height;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: $title-font;
  color: $title-color;
  text-transform: none;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 15px 0;
}

.h1,
h1 {
  font-size: 75px;
  line-height: 0.9333em;
}

.h2,
h2 {
  font-size: 50px;
  line-height: 1.1em;
}

.h3,
h3 {
  font-size: 36px;
  line-height: 1.278;
}

.h4,
h4 {
  font-size: 24px;
  line-height: 1.333;
}

.h5,
h5 {
  font-size: 20px;
  line-height: 1.417;
}

.h6,
h6 {
  font-size: 18px;
  line-height: 1.5;
}

@include ml {
  .h1,
  h1 {
    font-size: 54px;
  }
}

@include lg {
  .h1,
  h1 {
    font-size: 44px;
    line-height: 1.3;
  }

  .h2,
  h2 {
    font-size: 40px;
    line-height: 1.25;
  }

  .h3,
  h3 {
    font-size: 30px;
  }

  .h4,
  h4 {
    font-size: 24px;
  }

  .h5,
  h5 {
    font-size: 20px;
  }

  .h6,
  h6 {
    font-size: 16px;
  }
}

@include sm {
  .h1,
  h1 {
    font-size: 40px;
  }

  .h2,
  h2 {
    font-size: 34px;
    line-height: 1.3;
  }

  .h3,
  h3 {
    font-size: 26px;
  }

  .h4,
  h4 {
    font-size: 22px;
  }

  .h5,
  h5 {
    font-size: 18px;
  }

  .h6,
  h6 {
    font-size: 16px;
  }
}

@include xs {
  .h2,
  h2 {
    font-size: 30px;
  }
}

@include vxs {
  .h1,
  h1 {
    font-size: 34px;
  }
}
