/* Team global ---------------------------------- */
.team-card {
    background: $smoke-color;
    border-radius: 5px;
    padding: 10px 40px;
    transition: 0.4s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 2px solid transparent;
    gap: 20px;
    &_img {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        flex: none;
        img {
            border-radius: 5px;
            width: 134px;
            height: 134px;
            object-fit: cover;
            transition: 0.4s;
        }
    }
    &_content {
        width: 216px;
    }
    &_title {
        font-size: 36px;
        font-weight: 900;
        margin-bottom: 4px;
        margin-top: 0;
        a {
            color: $title-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
    &_desig {
        font-weight: 400;
        font-size: 18px;
        font-family: $title-font;
        color: $title-color;
    }
    &_text {
        max-width: 327px;
        p {
            margin-bottom: 0;
        }
    }
    &:hover {
        border-color: $theme-color;
        .team-card_img {
            img {
                transform: scale(1.05);
            }
        }
    }
}
@include lg {
    .team-card_title {
        font-size: 30px; 
    }
}
@include md {
    .team-card {
        flex-wrap: wrap;
        padding: 30px;
        justify-content: start;
        .team-card_img {
            order: 1;
        }
        .team-card_content {
            order: 2;
        }
        .team-card_title {
            margin-top: -0.3em;
        }
        .team-card_text {
            margin-top: 0;
            order: 3;
            max-width: none;
        } 
        .team-social_wrap {
            order: 4;
        }
    }
}
@include xs {
    .team-card .team-card_text {
        margin-top: -0.5em;
    }
}
/* Team Area 2 ---------------------------------- */
.team-card.style2 {
    display: block;
    padding: 40px;
    border-bottom: 5px solid $title-color;
    border-radius: 10px;
    border-left: 0;
    text-align: center;
    .team-card_img {
        width: 100%;
        img {
            width: 100%;
            min-height: 324px;
        }
    }
    .team-card_content {
        width: 100%;
        background: $white-color;
        border-radius: 5px;
        padding: 20px;
        position: relative;
        margin-top: 30px;
        .team-social_wrap {
            position: absolute;
            right: 20px;
            bottom: 30px;
            transform: scaleY(0);
            transition: 0.4s;
            .social-btn.style2 {
                display: block;
                a {
                    display: block;
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .team-card_title {
        font-size: 26px;
        font-weight: 900;
        margin-bottom: 0;
    }
    .team-card_desig {
        display: block;
    }
    &:hover {
        border-color: $theme-color;
        .team-social_wrap {
            transform: scaleY(1);
        }
    }
}
@include ml {
    .team-card.style2 {
        .team-card_content .team-social_wrap {
            bottom: auto;
            top: -30px;
            transform: translate(0px, calc(-100% - 20px)) scaleY(0);
        }
        &:hover {
            .team-card_content .team-social_wrap {
                transform: translate(0px, calc(-100% - 20px)) scaleY(1);
            }
        }
    }
}
@include lg {
    .team-card.style2 {
        padding: 30px;
    }
}
/* Team Area 3 ---------------------------------- */
.team-card.style3 {
    padding: 40px;
    display: block;
    text-align: center;
    border-left: 0;
    border-bottom: 2px solid transparent;
    .team-card_img {
        margin-bottom: 20px;
        img {
            width: 100%;
            height: auto;
        }

    }
    .team-card_title {
        font-size: 32px;
    }
    .team-card_content {
        width: auto;
    }
    .social-btn {
        margin-top: 20px;
        justify-content: center;
    }
    &:hover {
        border-color: $theme-color;
    }
}

/* Team Details ---------------------------------- */
.team-about-card {
    margin-bottom: 75px;
    .team-about-card_img {
        height: 100%;
        img {
            border-radius: 5px;
            height: 100%;
            object-fit: cover;
        }
    }
    .team-about-card_box {
        box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.05);
        padding: 40px;
        margin: 60px 0;
        border-radius: 5px;
        margin-left: -80px;
        background: $white-color;
    }
    &_title {
        margin-top: -0.3em;
        margin-bottom: 30px;
        font-weight: 900;
    }
}
.team-details-wrap {
    .team-details-wrap-title {
        font-size: 26px;
        font-weight: 900;
        margin-bottom: 0;
    }
    .team-details-wrap-desig {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 12px;
    }
}
.team-details-wrap_info {
    display: flex;
    gap: 20px;
    &:not(:last-child) {
        margin-bottom: 25px;
    }
    .icon {
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background: $smoke-color;
        flex: none;
    }
    p {
        margin-top: -0.3em;
        margin-bottom: 0;
        a {
            color: $title-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
}
@include md {
    .team-about-card .team-about-card_box {
        margin-left: 0;
    }
    .team-about-card {
        margin-bottom: 0;
    }
}