/* Counter 1 ---------------------------------- */
.counter-wrap1 {
    border-radius: 5px;
    background: $white-color;
    box-shadow: 0px 30px 80px 0px rgba(14, 100, 215, 0.04);
    margin-top: -136px;
    position: relative;
    z-index: 3;
    overflow: hidden;
}
.counter-card-wrap {
    padding: 40px 60px;
    .counter-card {
        min-width: 224px;
    }
}
.counter-checklist-wrap {
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 40px 60px;
    height: 100%;
    &:after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(90deg, #E8092E -1.06%, rgba(232, 9, 46, 0.00) 100%);
        z-index: -1;
    }
}
.counter-card {
    position: relative;
    display: inline-flex;
    gap: 25px;
    align-items: center;
    &_wrap {
        ~ .counter-card_wrap {
            border-left: 1px solid #2C2C2C;
            .counter-card.style2 {
                padding-left: 45px;
            }
        }
        &:last-child {
            .counter-card.style2 {
                padding-right: 0;
            }
        }
    }
    &_number {
        margin-top: 0px;
        margin-bottom: 3px;
        font-weight: 900;
        font-size: 50px;
        color: $title-color;
    }
    &_text {
        font-weight: 400;
        font-size: 18px;
        font-family: $title-font;
        color: $title-color;
        margin-bottom: -0.4em;
    }
    &_icon {
        border-radius: 50px;
        width: 80px;
        height: 80px;
        line-height: 80px;
        background-color: $theme-color;
        text-align: center;
        position: relative;
        margin: auto;
        img {
            transition: 0.4s all ease-in-out;
        }
    }
    &:hover {
        .counter-card_icon {
            &:after {
                margin: 0;
                border-width: 10px;
                border-color: $theme-color;
            }
            img {
                transform: rotateY(180deg);
            }
        }
    }
}

@include ml {
    .counter-card-wrap {
        padding: 40px 40px;
    }
    .counter-card_number {
        font-size: 44px;
    }
    .counter-card_icon {
        width: 70px;
        height: 70px;
        line-height: 70px;
    }
    .counter-card-wrap .counter-card {
        min-width: 214px;
    }
}
@include lg {
    .counter-card-wrap .counter-card {
        min-width: 185px;
    }
    .counter-card_number {
        font-size: 34px;
    }
    .counter-card_text {
        font-size: 16px;
    }
    .counter-card-wrap {
        padding: 30px 30px;
    }
    .counter-card-wrap .counter-card {
        min-width: max-content;
    }
}
@include md {
    .counter-card-wrap .counter-card {
        text-align: start;
    }
    .counter-card-wrap {
        padding: 30px 30px;
        text-align: center;
    }
}
@include xs {
    .counter-checklist-wrap {
        padding: 40px 40px;
    }
}

/* Counter 2 ---------------------------------- */
.counter-area-2 {
    padding: 60px;
    border-radius: 5px;
    margin-top: -111px;
    position: relative;
    z-index: 1;
}
.counter-card.style2 {
    gap: 20px;
    .counter-card_icon {
        background: $white-color;
    }
    .counter-card_number {
        color: $white-color;
    }
    .counter-card_text {
        color: $white-color;
    }
}
.counter-divider {
    height: 100%;
    width: 1px;
    background: $smoke-color;
}
@include xl {
    .counter-area-2 {
        padding: 60px 40px;
    }
}
@include md {
    .counter-area-2 {
        padding: 60px;
    }
}
@include sm {
    .counter-area-2 {
        padding: 40px;
    }
}

/****single-fact-wrap****/
.intro-area-2 {
    margin-top: 164px;
    background-size: cover;
    position: relative;
    z-index: 1;
    &:after {
        content: '';
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.75);
        z-index: -1;
    }
}

.fact-area {
	transform: translateY(-164px);
	margin-bottom: -164px;
}
.single-fact-wrap {
	text-align: center;
	background: #fff;
	box-shadow: 0px -10px 100px rgba(0, 6, 18, 0.08);
	border-radius: 5px;
	padding: 50px 30px 31px;
	.thumb {
		margin-bottom: 17px;
	}
	.details {
		h2 {
			font-size: 36px;
			margin-bottom: 5px;
		}
	}
}
.single-intro-wrap {
	text-align: center;
	background: linear-gradient(125.07deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
	backdrop-filter: blur(20px);
	border-radius: 5px;
	padding: 15px 15px 30px;
	.bg-border {
		position: absolute;
		left: 50%;
		top: 0;
		height: 100px;
		width: 100px;
		background: rgba(237, 28, 36, 0.6);
		border-radius: 50%;
		z-index: -1;
		transform: translate(-50%, -50%);
	}
	.thumb {
        height: auto;
        width: auto;
        transform: none;
        margin-bottom: 20px;
        img {
            border-radius: 5px;
            width: 100%;
        }
    }
	.details {
		h3 {
            margin-bottom: 0;
            color: $white-color;
            font-size: 26px;
        }
        p {
            margin: 0;
            color: $white-color;
        }
        .btn {
            margin-top: 20px;
            padding: 0 27px;
            height: 50px;
            line-height: 50px;
        }
	}
    &.style2 {
        padding: 0 25px 45px;
        margin-top: 50px;
        .thumb {
            height: 80px;
            width: 80px;
            background: #fff;
            border-radius: 50%;
            line-height: 80px;
            margin: auto;
            transform: translate(0, -50%);
            position: relative;
            z-index: 1;
            img {
                width: 52px;
            }
        }
        .details {
            p {
                margin-top: 20px;
                margin-bottom: 0;
            }
        }
        .btn {
            color: $white-color;
            margin-top: 40px;
            text-transform: capitalize;
        }
    }
}