.widget {
    margin-bottom: 40px;
    position: relative;
    padding: var(--widget-padding-x, 40px);   
    background: $smoke-color;
    border-radius: 5px;
    &[data-overlay]:before {
        z-index: -1;
    }
}
.widget_title {
    position: relative;
    font-size: 26px;
    font-weight: 900;
    font-family: $title-font;
    line-height: 1em;
    margin: -0.12em 0 25px 0;
    &:after {
        content: '';
        position: relative;
        display: inline-block;
        border-radius: 5px;
        width: 50px;
        height: 1px;
        background: $theme-color;
        margin-left: 12px;
        top: -6px;
    }
}

.widget {
    .search-form {
        position: relative;
        display: flex;
        input {
            background: $white-color;
            border: 0;
            flex: 1;
            color: $body-color;
            font-size: 18px;
            font-family: $title-font;
            font-weight: 400;
            border-radius: 5px;
            padding: 0 80px 0 20px;
            &::placeholder {
                color: $body-color;
            }
        }
        button {
            position: absolute;
            right: 0px;
            top: 0px;
            border: none;
            font-size: 16px;
            background-color: $theme-color;
            color: $white-color;
            display: inline-block;
            height: 60px;
            width: 60px;
            line-height: 60px;
            border-radius: 0px 5px 5px 0;
            &:hover {
                background: $title-color;
                color: $white-color;
            }
        }
    }
}
  
.wp-block-tag-cloud,
.tagcloud {     
    display: inline-flex;
    flex-wrap: wrap;
    gap: 13px;
    a {
        display: inline-block;
        border: 1px solid $border-color;
        font-size: 18px !important;
        font-weight: 400;
        font-family: $title-font;
        height: 38px;
        line-height: 38px;
        padding: 0px 10px;
        color: $body-color;
        background-color: transparent;
        border-radius: 5px;
        &:hover {
            color: $theme-color !important;
            border-color: $theme-color;
        }
    }
}
.widget_gallery {
    .insta-feed {
        margin-bottom: -10px;
        margin-left: -4px;
        margin-right: -4px;
    }
    a {
        width: 95px;
        height: 95px;
        position: relative;
        border-radius: 10px;
        display: inline-block;
        flex: none;
        margin: 0 4px 10px;
        img {
            border-radius: 10px;
        }
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: $theme-color;
            border-radius: 10px;
            transition: 0.4s;
            opacity: 0;
        }
        i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            opacity: 0;
            transition: 0.4s;
            color: $white-color;
            font-size: 20px;
        }
        &:hover {
            i {
                opacity: 1;
            }
            &:after {
                opacity: 0.7;
            }
        }
    }
} 
.base {
    border-radius: 6px;
    
    position: absolute;
    left: 1302px;
    top: 2098px;
    width: 80px;
    height: 78px;
    z-index: 209;
  }
  
.recent-post {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background: $white-color;
    border-radius: 5px;
    transition: 0.4s;
    &:last-child {
        margin-bottom: 0px;
        border: 0;
    }
    .media-img {
        flex: none;
        overflow: hidden;
        border-radius: 0px 5px 5px 0;
        img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 0px 5px 5px 0;
            transition: 0.4s ease-in-out;
        }
    }
    .media-body {
        padding: 10px 20px;
    }
    .post-title {
        font-weight: 700;
        font-size: 20px;
        margin: 0 0 5px;
        font-family: $title-font;
        text-transform: capitalize;
    }
    .recent-post-meta {
        margin-bottom: -2px;
        a {
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
            font-family: $title-font;
            color: $body-color;
            margin-bottom: 0px;
            display: block;
            i {
                margin-right: 6px;
                color: $theme-color;
                transition: 0.4s;
            }
            &:hover {
                color: $theme-color;
                i {
                color: $theme-color;
                }
            }
        }
    }
    &:hover {
        .media-img {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.sidebar-area {
    select,
    input {
        background-color: $white-color;
        border: 1px solid $border-color;
    }

}
.widget_shopping_cart {
    .widget_title {
        margin-bottom: 30px;
        border-bottom: none;
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
        }
    }

    .mini_cart_item {
        position: relative;
        padding: 30px 30px 30px 90px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 0;
        text-align: left;

        &:first-child {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .cart_list {
        a:not(.remove) {
            display: block;
            color: $body-color;
            font-size: 16px;
            font-weight: 500;
            font-family: $title-font;
            font-weight: 600;
            color: $title-color;

            &:hover {
                color: $theme-color;
            }
        }

        a.remove {
            position: absolute;
            top: 50%;
            left: 95%;
            transform: translateY(-50%);
            color: $body-color;

            &:hover {
                color: $theme-color;
            }
        }

        img {
            width: 75px;
            height: 75px;
            position: absolute;
            left: 0;
            top: 18px;
        }
    }

    .quantity {
        display: inline-flex;
        white-space: nowrap;
        vertical-align: top;
        margin-right: 20px;
        font-size: 14px;
        font-weight: 500;
    }

    .total {
        margin-top: 20px;
        font-size: 18px;
        color: $title-color;
        font-family: $body-font;
        strong {
            font-family: $title-font;
        }
    }

    .amount {
        padding-left: 5px;
    }
}
// Widget service list --------------------
.widget_service-list {
    padding: 40px 30px 30px;
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            background: $white-color;
            border: 0;
            box-shadow: 0px 16px 51px rgba(0, 0, 0, 0.07);
            border-radius: 5px;
            height: 60px;
            line-height: 60px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-bottom: 20px;
            }
            a {
                color: $title-color;
                line-height: 1.2;
                display: flex;
                gap: 10px;
                &:hover {
                    color: $theme-color;
                }
            }
            i {
                color: $theme-color;
            }
        }
    }
}
.widget_contact {
    .widget-contact-wrap {
        background: $white-color;
        border-radius: 5px;
        padding: 35px 30px;
        .widget_subtitle {
            color: $theme-color;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            font-family: $title-font;
            margin-top: -0.3em;
        }
        .widget_title {
            margin-bottom: 16px;
            &:after {
                display: none;
            }
        }
        .widget-contact-text {
            font-size: 18px;
            font-weight: 400;
            font-family: $title-font;
            margin-bottom: 0;
        }
        .icon {
            height: 55px;
            width: 55px;
            line-height: 55px;
            border-radius: 50%;
            background: $theme-color;
            display: inline-block;
            color: $white-color;
            margin-top: 16px;
            margin-bottom: 16px;
        }
        h5 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: -0.3em;
            margin-top: 3px;
            a {
                color: $title-color;
            }
        }
    }
}
.widget_project-info {
    margin-top: -95px;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
    background: $white-color;
    border-radius: 0 5px 5px 5px;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            font-size: 18px;
            font-weight: 400;
            font-family: $title-font;
            &:not(:last-child) {
                border-bottom: 1px solid $border-color;
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
            span {
                font-size: 20px;
                font-weight: 700;
                font-family: $title-font;
                color: $title-color;
                min-width: 90px;
                display: inline-block;
            }
        }
        .rating {
            color: $yellow-color;
            font-size: 14px;
            margin-bottom: -0.3em;
        }
    }
    @include md {
        margin-top: 0;
    }
}
.wp-block-calendar tbody td,
.wp-block-calendar th {
    padding: 10px;
}

.wp-block-calendar,
.calendar_wrap {
    position: relative;
    background-color: #fff;
    padding-bottom: 0;
    border: none;

    span[class*="wp-calendar-nav"] {
        position: absolute;
        top: 9px;
        left: 20px;
        font-size: 14px;
        color: $white-color;
        font-weight: 400;
        z-index: 1;
        line-height: 1.7;

        a {
            color: inherit;
        }
    }

    span.wp-calendar-nav-next {
        left: auto;
        right: 20px;
    }

    caption {
        caption-side: top;
        text-align: center;
        color: $white-color;
        background-color: $theme-color;
    }

    th {
        font-size: 14px;
        padding: 5px 5px;
        border: none;
        text-align: center;
        border-right: 1px solid #eee;
        color: $title-color;
        font-weight: 500;
        &:first-child {
            border-left: 1px solid #eee;
        }
        &:last-child {
            border-right: 1px solid #eee;
        }
    }
    table {
        th {
            font-weight: 500;
        }
    }

    td {
        font-size: 14px;
        padding: 5px 5px;
        color: #01133c;
        border: 1px solid #eee;
        text-align: center;
        background-color: transparent;
        transition: all ease 0.4s;
    }

    #today {
        color: $theme-color;
        background-color: $white-color;
        border-color: #ededed;
    }

    thead {
        background-color: #fff;
    }

    .wp-calendar-table {
        margin-bottom: 0;
    }

    .wp-calendar-nav {
        .pad {
            display: none;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            color: $title-color;
        }
    }
}
/***wp-calender***/
.wp-block-calendar {
    margin-bottom: 30px;
    border: none;
    padding-bottom: 0;

    table {
        caption {
            color: $white-color;
        }
    }
}
/********widget_recent_comments********/
.widget_recent_comments,
.wp-block-latest-comments {
    ul {
        list-style-type: none;
        padding-left: 0;
    }
}
ul.widget_recent_comments,
ol.widget_recent_comments,
.wp-block-latest-comments {
    margin-top: -0.11em;
    padding-left: 0;
}

.widget_recent_comments,
.wp-block-latest-comments {
    ol,
    ul {
        margin-bottom: 0;
    }
    li {
        margin-bottom: 0;
        color: $body-color;
        padding-left: 30px;
        position: relative;
        &:before {
            content: "\f086";
            position: absolute;
            left: 0;
            top: -1px;
            color: var(--theme-color);
            font-family: var(--icon-font);
        }
    }
    &.has-avatars {
        li {
            padding-left: 0;
            padding-bottom: 0 !important;
            &:before {
                display: none;
            }
        }
    }
    .avatar {
        margin-top: 0.4em;
    }
    li:not(:last-child) {
        padding-bottom: 12px;
    }
    article {
        line-height: 1.5;
    }
    a {
        color: inherit;
        &:hover {
            color: $theme-color;
        }
    }
}
/******widget_recent_entries******/
.widget_recent_entries {
    ul {
        margin: -0.3em 0 0 0;
        padding: 0;
        list-style: none;

        li {
            > a {
                color: $body-color;
                font-weight: 500;
                display: inline-block;

                &:hover {
                    color: $theme-color;
                }
            }

            span.post-date {
                font-size: 14px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #E6E6E6;
                padding-bottom: 12px;
                margin-bottom: 12px;
            }
        }
    }
}
/*******widget_rss*******/
ul,
ol {
    &.widget_rss,
    &.wp-block-rss {
        padding-left: 0;
    }
}
.widget_rss,
.wp-block-rss {
    list-style-type: none;
    ul {
        margin: -0.2em 0 -0.5em 0;
        padding: 0;
        list-style: none;

        .rsswidget {
            color: var(--title-color);
            font-family: var(--theme-font);
            font-size: 18px;
            display: block;
            margin-bottom: 10px;
        }

        .rssSummary {
            font-size: 14px;
            margin-bottom: 7px;
            line-height: 1.5;
        }

        a {
            display: block;
            font-weight: 600;
            color: inherit;

            &:hover {
                color: $theme-color;
            }
        }

        .rss-date {
            font-size: 14px;
            display: inline-block;
            margin-bottom: 5px;
            font-weight: 400;
            color: $title-color;

            &:before {
                content: "\f073";
                font-family: $icon-font;
                margin-right: 10px;
                font-weight: 300;
                color: $theme-color;
            }
        }

        cite {
            font-weight: 500;
            color: $title-color;
            font-family: $body-font;
            font-size: 14px;

            &:before {
                content: "";
                position: relative;
                top: -1px;
                left: 0;
                width: 20px;
                height: 2px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 8px;
                background-color: $theme-color;
            }
        }
    }

    li:not(:last-child) {
        margin-bottom: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 16px;
    }
    a {
        &:hover {
            color: $theme-color;
        }
    }
}
.wp-block-latest-comments__comment {
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
        .wp-block-latest-comments__comment-excerpt p {
            margin-bottom: -0.3em;
        }
    }
}
ul.wp-block-latest-posts {
    li {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: -0.3em;
        }
    }
}
/*Price Filter --------------*/
.price_slider_wrapper {
    margin-top: 0;
    .price_label {
        margin-bottom: -0.6em;
        span {
            display: inline-block;
            color: $light-color;
        }
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: $title-font;
        color: $light-color;
    }

    .ui-slider {
        height: 1px;
        position: relative;
        width: 100%;
        background-color: #e0e0e0;
        border: none;
        margin-top: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        border-radius: 0;
    }

    .ui-slider-range {
        border: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 1;
        display: block;
        background-color: $theme-color;
    }

    .ui-slider-handle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        text-align: center;
        line-height: 10.5px;
        padding: 0;
        border: none;
        cursor: pointer;
        position: absolute;
        margin-top: -7px;
        z-index: 2;
        background-color: $white-color;
        border: 1px solid $theme-color;
        transform: translateX(-1px);

        &:focus {
            outline: none;
            box-shadow: 1.5px 2.598px 10px 0px rgba(0, 0, 0, 0.15);
        }
        &:last-child {
            transform: translateX(-9px);
        }
    }
    button,
    .button {
        background: transparent;
        color: $title-color;
        font-weight: 600;
        line-height: 1.6;
        text-transform: uppercase;
        text-align: center;
        border-radius: 50px;
        border: none;
        display: inline-block;
        overflow: hidden;
        position: relative;
        z-index: 2;
        padding: 0;
        font-size: 16px;
        transition: 0.4s ease-in;
        margin-left: auto;
        &:hover {
            color: $theme-color;
            &:after,
            &:before {
                display: none;
            }
        }
    }
}
@include lg {
    .widget {
        --widget-padding-y: 30px;
        --widget-padding-x: 30px;
    }
    .widget_title {
        font-size: 22px;
        margin: -0.12em 0 24px 0;
    }
    .author-widget-wrap .name {
        font-size: 22px;
    }
}

@include md {
    .sidebar-area {
        padding-top: 40px;
    }
    .widget {
        --widget-padding-y: 40px;
        --widget-padding-x: 40px;
    }
}

@include sm {
    .widget_info {
        padding: 0;
    }
    .author-widget-wrap {
        padding: 0;
    }
    .widget_info .widget_title {
        padding: 24px 30px 19px 28px;
    }
    .info-list {
        padding: 0 30px 30px;
    }
    .widget {
        padding: 30px;
    }
    .widget_banner {
        padding: 0;
    }
    .widget_estimate .estimate-wrap span {
        margin-bottom: 16px;
    }
    .widget_estimate .content p {
        margin-bottom: 25px;
    }
    .widget_estimate .content h5 {
        margin-bottom: 23px;
    }
    .widget_estimate .content .checklist {
        margin-bottom: 30px;
    }
}
@include vxs {
    .widget_schedule ul li {
        font-size: 14px;
    }
}
@media (max-width: 330px) {
    .recent-post {
        .post-title {
            font-size: 16px;
            line-height: 24px;
        }
        .recent-post-meta a {
            font-size: 12px;
        }
    }
    .recent-post .media-img {
        width: 67px;
    }
    .recent-post .media-img {
        margin-right: 15px;
    }
    .widget_schedule ul li {
        font-size: 12px;
        padding: 0 15px;
    }
}

.sidebar-shop {
    .widget_title {
        color: $body-color;
        margin-bottom: 30px;
        font-size: 22px;
        &:after {
            display: none;
        }
    }
    .widget_search {
        .widget_title {
            margin-bottom: 20px;
        }
    }
    .widget {
        padding: 30px 20px 20px;
        border: 1px solid #E1E1E1;
        border-radius: 5px;
        background: transparent;
        .search-form {
            border: 1px solid #E1E1E1;
            border-radius: 5px;
        }
        button {
            border-radius: 5px;
            right: 3px;
            top: 3px;
            height: 54px;
            width: 54px;
            line-height: 54px;
        }
    }
    .widget_categories {
        li {
            &:not(:last-child) {
                a {
                    border-bottom: 1px solid #E1E1E1;
                    padding-bottom: 17px;
                    margin-bottom: 18px;
                }
            }
            a {
                padding: 0;
                border: 0;
            }
        }
    }
    .product_ratting_widget {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            margin-top: -0.3em;
            li {
                color: $title-color;
                font-size: 18px;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
                .star-rating {
                    color: $yellow-color;
                    .unavailable {
                        color: $light-color;
                        opacity: 0.3;
                    }
                }
            }
        }
    }

}

