/* Blog Card ---------------------------------- */
.blog-shape-img1 {
  opacity: 0.2;
  left: 66px;
  top: 250px;
  .about1-shape-img-2 {
    margin-left: -35px;
    margin-bottom: -120px;
  }
  @include xxl {
    right: 42px;
    top: 140px;
  }
}
.blog-card {
  position: relative;
  padding-bottom: 40px;
  .blog-title {
    font-weight: 900;
    font-size: 26px;
    color: $title-color;
    margin-bottom: 35px;
    margin-top: 12px;
  }
  .blog-img {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    img {
      max-width: 100%;
      border-radius: 5px;
      transition: 0.4s ease-in-out;
      min-height: 340px;
      object-fit: cover;
    }
    .blog-date {
      position: absolute;
      right: 30px;
      bottom: 55px;
      a {
        background: $title-color;
        height: 80px;
        width: 80px;
        border-radius: 5px;
        text-align: center;
        display: inline-block;
        color: $white-color;
        font-family: $title-font;
        font-size: 18px;
        font-weight: 400;
        padding: 13px 10px 17px;
        span {
          display: block;
          font-size: 36px;
          font-weight: 700;
          margin-bottom: 7px;
        }
      }
    }
  }
  .blog-content {
    padding: 40px;
    position: relative;
    z-index: 2;
    transition: 0.4s;
    border-radius: 5px 0 0 5px;
    background: $white-color;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
    margin-top: -25px;
    margin-left: 30px;
  }
  &:hover {
    .blog-img {
      img {
        transform: scale(1.08);
      }
    }
  }
}
@include lg {
  .blog-card .blog-title {
    font-size: 24px;
  }
}
@include xs {
  .blog-card .blog-content {
    margin-left: 25px;
    padding: 30px;
  }
  .blog-card .blog-title {
    margin-bottom: 25px;
  }
}
@include vxs {
  .blog-card .blog-content {
    margin-left: 0px;
    margin-top: 0;
    border-radius: 0 0 5px 5px;
  }
  .blog-card .blog-title {
    font-size: 22px;
  }
  .blog-card .blog-img {
    border-radius: 5px 5px 0 0;
    img {
      border-radius: 5px 5px 0 0;
      min-height: 300px;
    }
    .blog-date {
      bottom: 30px;
    }
  }
}

/* Blog Card 2---------------------------------- */
.blog-card.style2 {
  .blog-img img {
    width: 100%;
  }
  .blog-img .blog-date {
    bottom: auto;
    right: auto;
    top: 0;
    left: 30px;
    a {
      border-radius: 0 0 5px 5px;
      background: $theme-color;
    }
  }
  .blog-content {
    margin-right: 30px;
    border-radius: 5px;
    text-align: center;
    border-bottom: 2px solid $theme-color;
    padding: 35px 35px 27px;
  }
  .blog-title {
    margin-top: 21px;
    margin-bottom: 33px;
  }
}
@include vxs {
  .blog-card.style2 .blog-content {
    margin-right: 0;
  }
  .blog-card.style2 .blog-content {
    padding: 35px 25px 27px;
  }
}
