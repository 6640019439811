/* Client 1 ---------------------------------- */
.client-bg-area {
    background-size: 100% calc(100% - 228px);
    background-repeat: no-repeat;
}
.client-area-1 {
    padding: 100px 0;
    @include md {
        padding: 80px 0;
    }
}

/* Client 2 ---------------------------------- */
.client-bg-area-2 {
    background-size: 100% calc(100% - 382px);
    background-repeat: no-repeat;
}

/* Client 3 ---------------------------------- */
.client3-wrap {
    border-radius: 20px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
    padding: 100px 45px 90px;
    @include vxs {
        padding: 80px 20px 50px;
    }
}
.client-slider3 {
    overflow: hidden;
    background: #F4F4F4;
    border-radius: 20px;
    padding: 120px 0;
    .slider1 {
        margin: 0 -50px;
    }
    .slider2 {
        margin: 0 -80px 0 0;
        margin-top: 24px;
    }
}
