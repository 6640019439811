/* Marquee 1---------------------------------- */
.slider__marquee {
  overflow: hidden;
  margin: 0;
  margin-bottom: -0.5em;
  .item {
    display: inline-flex;
    margin-right: 40px;
    padding: 10px 0;
    a {
      font-size: 75px;
      font-weight: 900;
      font-family: $title-font;
      color: $title-color;

      img {
        margin-right: 37px;
        margin-top: -8px;
      }
      span {
        display: inline-block;
        line-height: 1;
        &.text-stroke {
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke: 1px $title-color;
        }
      }
      @include lg {
        font-size: 60px;
        img {
          margin-top: 0;
        }
      }
    }
  }
}
